import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import DataReportContent from "../components/training-webinars/data-report-content"
import Hero from "../components/training-webinars/data-report-hero"

const SoftwareAccess = () => (
  <div className="contactPage softwareAccess trainingWebinars">
    <Layout>
      <Seo title="Software Access" />
      <div className="contactSecWrapper">
        <Hero />
        <DataReportContent />
      </div>
    </Layout>
  </div>
)

export default SoftwareAccess

